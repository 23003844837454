export const Header = (props) => {
  return (
    <header id="header">
      {/* <video
        autoPlay
        playsInline
        muted
        loop
        id="background-video"
      >
        <source
          src="./img/video_bg.mp4"
          type="video/mp4"
        />
      </video> */}

      <div className="intro ">
        <div id="background-img"></div>
        <div className="overlay">
          <div className="container">
            <div className="intro-text">
              <h1>
                {/* {props.data ? props.data.title : "Loading"} */}
                <img
                  src="./img/a1_logo-white.png"
                  alt="A1 Logo"
                />
                <span></span>
              </h1>
              <p>
                {props.data
                  ? props.data.paragraph
                  : "Loading"}
              </p>
              {/* <div
                id="background-video"
                // src="./img/u1474232619_I_want_a_construction_site_of_a_concrete_and_stee_d1a95727-8c04-4e3f-8d7c-040fd6604cb4_0.png"
              >
                as
              </div> */}
              <a
                id="intro-btn"
                href="#tjanster"
                className="btn btn-custom btn-lg page-scroll"
              >
                Läs mer
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
