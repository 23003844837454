export const Contact2 = (props) => {
  return (
    <div id="kontakt">
      <div className="text-center">
        <div className="section-title">
          <h2>Kontakt</h2>{" "}
          <p>
            Har du några frågor? Du är alltid
            välkommen att höra av dig!
          </p>
        </div>
        <div className="contact-item">
          <p>
            <span>
              <i className="fa fa-map-marker"></i>{" "}
              <strong> Adress:</strong>
            </span>{" "}
            {props.data
              ? props.data.address
              : "loading"}
          </p>
        </div>
        <div className="contact-item">
          <p>
            <span>
              <i className="fa fa-phone"></i>{" "}
              <strong>Telefon:</strong>
            </span>{" "}
            {props.data
              ? props.data.phone
              : "loading"}
          </p>
        </div>
        <div className="contact-item">
          <p>
            <span>
              <i className="fa fa-envelope-o"></i>{" "}
              <strong>Email:</strong>
            </span>{" "}
            {props.data
              ? props.data.email
              : "loading"}
          </p>
        </div>{" "}
        <div className="">
          <br />
          {props.data
            ? props.data.career.map((para) => (
                <p className="carrer-text">
                  {para}
                </p>
              ))
            : "loading"}
        </div>{" "}
        <div className="social">
          <ul>
            {" "}
            {/* <li>
              <a
                href={
                  props.data
                    ? props.data.linkedin
                    : "/"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </li> */}
            {/* <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li> */}
          </ul>
        </div>{" "}
        <div id="">
          <div className="text-center">
            <p>
              &copy; 2021-2024 A1 Konstruktion
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
